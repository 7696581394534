<template>
  <div
    class="flex items-center"
    :class="[
      'bg-gray-800/75',
      'border border-solid border-gray-700 duration-100 focus:border-blue-600',
      'text-xs text-gray-100',
      'px-2',
      'rounded'
    ]"
    @mouseenter="hoverActive = true"
    @mouseleave="hoverActive = false"
  >
    <CalendarIcon v-if="customIcon" size="h-3.5 w-3.5 text-gray-500" />
    <svg
      v-else-if="!showClearBtn"
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <svg
      v-else-if="showClearBtn"
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 cursor-pointer"
      :class="iconClass"
      @click="clearDate()"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>

    <date-picker
      :range="range"
      ref="picker"
      v-model="defaultSelectedDate"
      :value="defaultSelectedDate"
      :format="format"
      value-type="YYYY-MM-DD"
      :input-class="[fontSize, 'text-white']"
      :class="[fontSize, 'px-1']"
      :style="customStyle"
      :placeholder="placeholder"
      :append-to-body="appendToBody"
      @change="$emit('change', $event)"
      @close="$emit('close', $event)"
      :disabled="disabled"
      :disabled-date="disabledFutureDate ? disabledFutureDate : oneYearSpan"
    >
    </date-picker>
  </div>
</template>
<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { CalendarIcon } from '@heroicons/vue/20/solid';
import { ref, watch, computed } from 'vue';

const $emits = defineEmits(['change', 'close', 'clear']);
const props = defineProps({
  placeholder: { type: String, default: 'Start Date - End Date' },
  defaultDate: { type: Array, default: () => [] },
  format: { type: String, default: 'MMMM DD, YYYY' },
  fontSize: { type: String, default: 'text-xs' },
  customStyle: { type: String, default: '' },
  disabledFutureDate: { type: Function, default: null },
  range: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  appendToBody: { type: Boolean, default: true },
  customIcon: { type: Boolean, default: false }
});

const hoverActive = ref(false);
const defaultSelectedDate = ref(props.range ? props.defaultDate : props.defaultDate[0]);
// COMPUTED
const showClearBtn = computed(() => {
  let dateSelected = Array.isArray(defaultSelectedDate.value)
    ? defaultSelectedDate.value.length
    : defaultSelectedDate.value;
  return hoverActive.value && dateSelected;
});
const oneYearSpan = computed(() => {
  return date => date >= new Date(new Date().setFullYear(new Date().getFullYear() + 1));
});
// METHODS
function clearDate() {
  defaultSelectedDate.value = props.range ? [] : '';
  $emits('clear');
}
// WATCH
watch(
  () => props.defaultDate,
  () => {
    defaultSelectedDate.value = props.range ? props.defaultDate : props.defaultDate[0];
  }
);
</script>
